import React from 'react'
import { Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import './Landing.scss'
import landingImage from '../../assets/landing-image.svg'


function Landing(){
    return(
        <div className = "landing-page">
            <div className='left-container'>
                <h2>Regain Ownership of your Time</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit nec, faucibus sit amet. Ultrices placerat semper odio at tincidunt eu blandit. Nullam neque, suspendisse duis aliquam et. Donec risus, mauris eu ac. Enim neque egestas etiam consequat egestas. Sed faucibus quam sit metus.</p>
                <Link to = '/signup' className='center-align'>
                <Button 
                    icon = { <RightOutlined /> }
                    size = 'large'
                    shape = 'round'
                    className='cta-btn'
                    
                >Get Started
                </Button>
                </Link>
            </div>
            <div className='right-container'>
                <img src={ landingImage } />
            </div>
        </div>
    )
}

export default Landing