import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../App'
import SwitchContainer from './components/SwitchContainer/SwitchContainer'
import AccountContainer from './components/AccountContainer/AccountContainer'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, notification } from 'antd'
import MessageChange from './components/MessageChange/MessageChange'
import ScheduleContainer from './components/ ScheduleContainer/ScheduleContainer'
import GeneralQueue from './components/GeneralQueue/GeneralQueue'
import firebase from 'firebase'
// import { start, signInCallback } from '../../config/googleAuth'
import { clientId, scopes } from '../../config/credentials'
import './Dashboard.scss'

function Dashboard(){
    const [oooToggle, setOooToggle] = useState(false)                          // Handles the state of the main OOO toggle
    const [activeStatus, setActiveStatus] = useState('Manual OOO Active')      // For customizing the message in the Switch later on
    const [manualOOOLength, setManualOOOLength] = useState('1')                // Handles the length of the OOO in for the Switch
    const [accountMessages, setAccountMessages] = useState({})                 // An array of all the associated accounts
    const [accountStatus, setAccountStatus] = useState({})                     // Status of the toggles in the accounts
    const [messageDrawer, setMessageDrawer ] = useState(false)                 // The visibility of the custom message drawer
    const [messageAccount, setMessageAccount] = useState('')                   // the account associated with the message change
    const [customMessage, setCustomMessage] = useState('')                     // The string for the custom autoreply message the user will be storing
    const [noAccounts, setNoAccounts] = useState(false)                        // True if the user has no accounts to  display a special message in the account container
    const [loading, setLoading] = useState(true)                               // Handles when the system is fetching data
    const [switchLoading, setSwitchLoading] = useState(false)                  // Handles the loader SVG on the main switch
    const {userInfo} = useContext(UserContext)
    const [scheduledEvents, setScheduledEvents] = useState([])                 // Events created from the Scheduled events from the UI
    const [generalQueue, setGeneralQueue] = useState([])                       // Events created from calendar as well as the UI
    const [scheduleLoader, setScheduleLoader] = useState(true)


    const loadUserInformation = () => {
        setLoading(true)
        //Pull the user information
        const getuserInfo = firebase.functions().httpsCallable('getUserInformation')
        getuserInfo()
        .then(response=>{
            const emails = [...response.data[0]]
            const status = [...response.data[1]]
            const messages = [...response.data[2]]
            let tempStatus = {}
            let tempMessages = {}
        
            if(emails.length > 0 && status.length > 0){
                for(let i = 0; i<emails.length; i++){
                    tempStatus[emails[i]] = status[i]
                    tempMessages[emails[i]] = messages[i]
                }
                setAccountStatus({...tempStatus})
                setAccountMessages({...tempMessages})
            }
            else{
                setAccountStatus({})
                setAccountMessages({})
            }
        })
        .catch(error=>{
            window.alert(error.message)
        })
    }

    //Initial user information load
    useEffect(()=>{
        loadUserInformation()
    },[])

    const returnUIGenerated = (scheduleObject) => {
        if(scheduleObject.uiSchedule){
            return scheduleObject
        }
    }

    useEffect(()=>{
        console.log('sceduled events array (nested in useEffect in Dashboard): ',scheduledEvents)
    },[scheduledEvents])

    //Subscribe to changes in the database for updating the OOO toggle
    useEffect(()=>{
        const userId = firebase.auth().currentUser.uid
        const toggleRef = firebase.firestore().collection('users').doc(userId)
        toggleRef.onSnapshot(doc =>{
            setScheduleLoader(true)
            setGeneralQueue([...doc.data().OOOSchedules])
            let uiGeneratedEvents = doc.data().OOOSchedules.filter(returnUIGenerated)
            setScheduledEvents([...uiGeneratedEvents])

            
            if(oooToggle != doc.data().outOfOffice){
                setOooToggle(doc.data().outOfOffice)
            }
        })
    },[])

    //Enable the custom message drawer, once the email is in the variable
    useEffect(()=>{
        if(messageAccount != ''){
            setMessageDrawer(true)
        }
    },[messageAccount])

   

    //Modal for customizing the message for the email autoreply
    const showCustomMessageForm = (email) => {
        setMessageAccount(email)
        //The useEffect above is handles the boolean to open the drawer, but the email has to be set first
    }

    // STORE THE MESSAGE IN THE DATABASE
    const messageEditSubmit = (value) => {

        //Dentro del then de la funcion hay que insertar esto
        
        var storeMessage = firebase.functions().httpsCallable('storeMessage');
        
        storeMessage ({
            email: messageAccount,
            message: value.autoreplyMessage
        }).then(result => {
            setMessageAccount('')
            setCustomMessage('')
            setMessageDrawer(false)
            let tempMessages = {...accountMessages}
            tempMessages[messageAccount] = value.autoreplyMessage
            setAccountMessages({...tempMessages})
            notification.success({
                message: 'Message changed',
                description: 'It will be shown when users email you if you have your Out of Office active'
            })

        }).catch(error => {
            notification.error({
                message: 'Something went wrong',
                description: 'Please try again in a moment'
            })
        })
    }

    const messageEditCancel = () => {
        setMessageAccount('')
        setMessageDrawer(false)
        setCustomMessage('')
    }

    // Dropdown change handler
    const handleTimeChange = (value) => {
        setManualOOOLength(value);
    }

    const { confirm } = Modal
    
    const confirmManualOOO = () => {
        let message;
        if(manualOOOLength<1){
            message = `Are you sure that you want to set yourself Out of Office for ${manualOOOLength * 60} minutes?`
        }
        else if(manualOOOLength == 1){
            message = `Are you sure that you want to set yourself Out of Office for ${manualOOOLength} hour?`
        }
        else{
            message = `Are you sure that you want to set yourself Out of Office for ${manualOOOLength} hours?`
        }
        confirm({
            title: message,
            icon: <ExclamationCircleOutlined />,

            onOk(){
                var toggleOOO = firebase.functions().httpsCallable('toggleAccountsVacationResponder')
                const startTime = null
                const endTime = manualOOOLength
                const title = null
                setSwitchLoading(true)

                toggleOOO ({
                    outOfOffice: true,
                    startTime: startTime,   
                    endTime: endTime,
                    title: title
                }).then(result => {
                    setSwitchLoading(false);
                }).catch(error => {
                    window.alert(error.message)
                })

            },
            oncancel(){

            }
        })
    }

    // Handles OOO activation
    const handleSwitchChange = (status) => {
        //Make the call to the firestore to update the toggle
        if(status){
            confirmManualOOO();
        }
        else{
            setSwitchLoading(true)
            var toggleOOO = firebase.functions().httpsCallable('toggleAccountsVacationResponder')
            const startTime = null
            const endTime = manualOOOLength
            const title = null
            toggleOOO({
                outOfOffice: false,
                startTime: startTime,   
                endTime: endTime,
                title: title
            }).then(result => {
                setOooToggle(false)
                setSwitchLoading(false)
            }).catch(error => {
                window.alert(error.message);
            }) 
        }
    
        // var toggleOOO = firebase.functions().httpsCallable('toggleAccountsVacationResponder');
        // const startTime = null;
        // const endTime = manualOOOLength;   
        // const title = null;              // Title 
    
        // if (status) {
        //     // Activates OOO
        //     toggleOOO ({
        //         outOfOffice: true,
        //         startTime: startTime,   
        //         endTime: endTime,
        //         title: title
        //     }).then(result => {
        //         console.log(result);
        //     }).catch(error => {
        //         console.log(error);
        //     })
        // }
    
        // else {
        //     // Deactivates OOO
        //     toggleOOO ({
        //         outOfOffice: false,
        //         startTime: startTime,
        //         endTime: endTime,
        //         title: title
        //     }).then(result => {
        //         console.log(result);
        //     }).catch(error => {
        //         console.log(error);
        //     })
        // }
       

    }
    
    const handleAccountSwitch = async (email) => {
        let tempStatus = {...accountStatus}
        tempStatus[email] = !tempStatus[email]
        
        const toggleAccount =  firebase.functions().httpsCallable('toggleAccount');
        try{
            const response = await toggleAccount({
                email: email,
                active: tempStatus[email] 
            })
            setAccountStatus({...tempStatus})
        }
        catch(error){
            window.alert(error.message)
        }
    }


    return(
        <div className = "dashboard-page">
            { messageDrawer &&
            (<MessageChange 
                messageDrawer = { messageDrawer }
                messageAccount = { messageAccount }
                setMessageAccount = { setMessageAccount }
                setMessageAccount = { setMessageAccount }
                messageEditSubmit = { messageEditSubmit }
                messageEditCancel = { messageEditCancel }
                accountMessages = { accountMessages }
            />)}
            <GeneralQueue 
                generalQueue = { generalQueue }
            />
            <div className="header-container">
                <h1>Welcome { userInfo.firstName }!</h1>
            </div>
            <div className="dashboard-body">
                <div className="column">
                    <SwitchContainer 
                        handleSwitchChange = { handleSwitchChange } 
                        handleTimeChange = { handleTimeChange }
                        oooToggle = { oooToggle }
                        switchLoading = { switchLoading }
                    />
                    <AccountContainer 
                        accountStatus = { accountStatus } 
                        accountMessages = { accountMessages }
                        showCustomMessageForm = { showCustomMessageForm }
                        messageAccount = { messageAccount }
                        setMessageAccount = { setMessageAccount }
                        loadUserInformation = { loadUserInformation }
                        noAccounts = { noAccounts }
                        setNoAccounts = { setNoAccounts }
                        loading = { loading }
                        setLoading = { setLoading }
                        handleAccountSwitch = { handleAccountSwitch }
                    />
                </div>
                <div className="column">
                    <ScheduleContainer 
                        scheduledEvents = { scheduledEvents }
                        scheduleLoader = { scheduleLoader}
                        setScheduleLoader = { setScheduleLoader }
                    />
                </div>

            </div>
        </div>
    )
}

export default Dashboard