import React, { useState, createContext, useEffect } from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect }  from 'react-router-dom'
import './App.scss';
import * as firebase from 'firebase'
import Landing from './scenes/Landing/Landing'
import Signup from './scenes/Signup/Signup'
import Dashboard from './scenes/Dashboard/Dashboard'
import NavBar from './components/NavBar/NarBar'
import Login from './scenes/Login/Login'
import UserSettings from './scenes/UserSettings/UserSettings'
import Suggestions from './scenes/Suggestions/Suggestions'

export const UserContext = createContext()

function App() {
  const [userAuth, setUserAuth] = useState(false)   //Boolean with the auth status of the user
  const [userInfo, setUserInfo] = useState({        //Object with the user information
      firstName: '',
      lastName: ''

  })      
  const [infoLoading, setInfoLoading] = useState(false)

  const loadName = () => {
    let user = firebase.auth().currentUser
    if(user.displayName){
      const splitName = user.displayName.split(" ")
      setUserInfo({
        firstName: splitName[0],
        lastName: splitName[1]
      })
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuth(true);
        loadName()
      } 
      else {
        setUserAuth(false);
      }
    });  
  }, [userAuth])



  return (
    <div className="App">
      {/* <UserContext.Provider value={{authState: [userAuth, setUserAuth], userInfo: [userInfo, setUserInfo]}}> */}
      <UserContext.Provider value = {{userAuth, setUserAuth, userInfo, setUserInfo, infoLoading, setInfoLoading, loadName }}>
        <Router>
          <NavBar />
          <Switch>
            {
              userAuth
              ? (
                <>
                {/* Aqui solo deberia ir Dashboard */}
                  <Route exact path='/'>
                    <Redirect to = '/dashboard' />
                  </Route>
                  <Route exact path='/login'>
                    <Redirect to = '/dashboard' />
                  </Route>
                  <Route exact path='/signup'>
                    <Redirect to = '/dashboard' />
                  </Route>
                  <Route exact path='/dashboard' component = { Dashboard } />
                  <Route path='/dashboard/user-settings' component = { UserSettings } />
                  <Route path='/dasboard/ai-suggestions' component = { Suggestions } />
                </>
              )
              : 
                (
                  <>
                  {/* Aqui deberian ir Landing, Signup y Login */}
                    <Route exact path='/' component = { Landing } />
                    <Route exact path='/signup' component = { Signup } />
                    <Route exact path='/login' component = { Login } />
                    <Route path='*'>
                      <Redirect to = '/' />
                    </Route>
                  </>
                )
            }
          </Switch>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
