import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCz3q56HlrbQkapOLvsVPCI-D7otivK_7k",
    authDomain: "i-am-very-ooo-sandbox.firebaseapp.com",
    databaseURL: "https://i-am-very-ooo-sandbox.firebaseio.com",
    projectId: "i-am-very-ooo-sandbox",
    storageBucket: "i-am-very-ooo-sandbox.appspot.com",
    messagingSenderId: "881083565546",
    appId: "1:881083565546:web:8eccd4864526d905025e93",
    measurementId: "G-8FTDGFB0G9"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
