import React, {useState, useEffect } from 'react'
import { Skeleton, Modal, Button } from 'antd'
import { CloseOutlined, ExperimentOutlined, DoubleRightOutlined } from '@ant-design/icons'
import firebase from 'firebase'
import './Suggestions.scss'


function Suggestions(){
    const [suggestionsLoader, setSuggestionsLoaders] = useState(false)
    const [aiEvents, setAiEvents] = useState([])

    const { confirm } = Modal

    useEffect(()=>{
        const userId = firebase.auth().currentUser.uid
        const toggleRef = firebase.firestore().collection('users').doc(userId)
        toggleRef.onSnapshot(doc =>{
            setAiEvents([...doc.data().OOOSuggestions])
            // setSuggestionsLoaders(true)
            // setGeneralQueue([...doc.data().OOOSchedules])
            // let aiGeneratedEvents = doc.data().OOOSchedules.filter(returnUIGenerated)
            // setAiEvents([...aiGeneratedEvents])

            
            // if(oooToggle != doc.data().outOfOffice){
            //     setOooToggle(doc.data().outOfOffice)
            // }
        })
    },[])


    const aiEventContainers = aiEvents.map(schedule => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }
        return(
            <div key = { schedule.id } className = 'ai-event-container' >
                <div className = 'heading-container'>
                    <div className='container-row'>
                        <ExperimentOutlined  className='container-icon'/>
                        <h3>{ schedule.title }</h3>
                    </div>
                    <div className = 'container-row'>
                        <p className ='email'>From [{schedule.creator}]</p>
                    </div>
                </div>
                <div className = 'container-row'>
                    <p className = 'description'>{schedule.description}</p>
                </div>
                <div className='container-row btn-row'>
                    <Button className='btn'>Discard</Button>
                    <Button type ='primary' className='btn'>Accept</Button>
                </div>
               
            </div>
        )
    })

    return(
        <div className='suggestions-page'>
            <h1>AI Powered Suggestions</h1>
            <p>Take your productivity to the next level by leveraging the power of AI. 
            By using artificial intelligence, we make suggestions of events that are likely for you want to be Out of Office for. 
            At the same time, telling us what's relevant and what's not, help us get better at generating these suggestions to take your 
            productivity to the next level!</p> 
            {aiEventContainers}
        </div>
    )
}

export default Suggestions